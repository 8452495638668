import {
  getDoc, getDocs,
  addDoc, updateDoc, deleteDoc, setDoc,
  doc, collection,
  query
} from 'firebase/firestore'

import { db } from '../lib/firebase'

const getDocProvider = async (resource) => {
  try {
    const ref = doc(db, resource)
    const snap = await getDoc(ref)
    if (snap.exists()) return snap.data()
    return null
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const getDocsProvider = async (resource, queryParams) => {
  try {
    const ref = collection(db, resource)
    const querySnap = await getDocs(queryParams ? query(ref, queryParams) : ref)
    return querySnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const setDocProvider = async (resource, data, merge) => {
  try {
    const ref = doc(db, resource)
    await setDoc(ref, data, { merge })
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const updateDocProvider = async (resource, data) => {
  try {
    const ref = doc(db, resource)
    await updateDoc(ref, data)
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const addDocProvider = async (resource, data) => {
  try {
    const ref = collection(db, resource)
    const docRef = await addDoc(ref, data)
    return docRef
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const deleteDocProvider = async (resource) => {
  try {
    const ref = doc(db, resource)
    await deleteDoc(ref)
  } catch (err) {
    console.error(err)
    switch (err.code) {
      case 'permission-denied':
        throw new Error('No cuentas con los permisos necesarios para realizar esta operación.')
      default:
        throw new Error(err.message)
    }
  }
}

const apiProvider = {
  getDoc: getDocProvider,
  getDocs: getDocsProvider,
  setDoc: setDocProvider,
  updateDoc: updateDocProvider,
  addDoc: addDocProvider,
  deleteDoc: deleteDocProvider
}

export default apiProvider
