import { useState, useCallback } from 'react'

import { apiCustomers } from '../api'

const useUpdateCustomer = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  const updateCustomer = useCallback((data, id) => {
    return new Promise((resolve, reject) => {
      setIsUpdating(true)
      Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])
      apiCustomers.update(data, id)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsUpdating(false)
        })
    })
  }, [])

  return { updateCustomer, isUpdating }
}

export default useUpdateCustomer
