import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCustomers } from '../api'

const useCustomers = () => {
  const { data, error, isLoading } = useSWR('getCustomers', () => apiCustomers.get())

  const customers = useMemo(() => {
    if (!isLoading && !error) {
      if (Array.isArray(data)) {
        return data.map((doc) => ({
          id: doc.id,
          name: `${doc.name} ${doc.lastName}${doc.sLastName ? ` ${doc.sLastName}` : ''}`,
          tel: doc.tel,
          state: doc.state,
          city: doc.city,
          key: doc.id
        }))
      }
      return null
    }
  }, [error, isLoading, data])

  return { data: customers, error, isLoading }
}

export default useCustomers
