import { useContext } from 'react'

import { NavigationContext } from '../context/Navigation'

const useNavigation = () => {
  const [navigation, setNavigation] = useContext(NavigationContext)

  return { navigation, setNavigation }
}

export default useNavigation
