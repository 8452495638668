import style from './Service.module.scss'
import { SERVICES, SERVICES_SPECS } from './services'

import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Input, Select, Typography, Col, Row, message, DatePicker, Radio, Space, Divider, InputNumber } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { Link, useSearchParams } from 'react-router-dom'
import debounce from 'lodash/debounce'

import Section from '../../commons/Section'
import DeceasedForm from './DeceasedForm'
import Item from './Item'

import useAddService from '../../../hooks/useAddService'
import useCustomer from '../../../hooks/useCustomer'
import useSearchCustomers from '../../../hooks/useSearchCustomers'

const { Title, Text } = Typography

const Add = ({ onSuccess }) => {
  const [form] = Form.useForm()
  const { addService, isSaving } = useAddService()
  const [results, search] = useSearchCustomers()
  const [searchParams] = useSearchParams()
  const { data: customer } = useCustomer(searchParams.get('customerId'))
  const [messageApi, contextHolder] = message.useMessage()
  const [itemsSpecs, setItemsSpecs] = useState([])
  const [showDeceasedForm, setShowDeceasedForm] = useState(false)

  const handleFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      date: fieldsValue.date?.format('YYYY-MM-DD'),
      deceasedDate: fieldsValue.deceasedDate?.format('YYYY-MM-DD')
    }
    if (typeof values.customer === 'object') {
      values.customer = values.customer.value
    }
    addService(values)
      .then(() => {
        onSuccess()
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  const handleRadioChange = (ev) => {
    const value = ev.target.value
    if (value === 'yes') {
      setShowDeceasedForm(false)
    } else {
      setShowDeceasedForm(true)
    }
  }

  const handleSelectChange = (value) => {
    setItemsSpecs(value)
  }

  const handleSearch = debounce((value) => search(value), 120)

  const handleAddition = useCallback((value) => {
    if (value !== null) {
      let addition = 0
      itemsSpecs.forEach((item) => {
        const itemCost = form.getFieldValue('specs_' + item + '_cost')
        if (isNaN(itemCost)) return
        addition += itemCost
      })
      form.setFieldValue('totalCost', addition)
    }
  }, [form, itemsSpecs])

  useEffect(() => {
    form.setFieldsValue({
      future: 'yes'
    })
    setShowDeceasedForm(false)
    if (customer) {
      form.setFieldsValue({
        customer: {
          value: searchParams.get('customerId'),
          label: `${customer.name} ${customer.lastName}${customer.sLastName ? ` ${customer.sLastName}` : ''}`
        }
      })
    }
  }, [form, customer, messageApi, searchParams])

  useEffect(() => {
    const totalCost = form.getFieldValue('totalCost')

    if (totalCost === 0 || totalCost) handleAddition(0)
  }, [itemsSpecs, form, handleAddition])

  return (
    <Form
      name='customer'
      form={form}
      layout='vertical'
      onFinish={handleFinish}
      autoComplete='off'
      className={style.container}
      scrollToFirstError
    >
      {contextHolder}
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Detalles del servicio</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={12}
            xl={8}
          >
            <Form.Item
              label='Fecha de contratación'
              name='date'
              rules={[
                {
                  required: true,
                  message: 'Necesitas seleccionar una fecha'
                }
              ]}
              required
              hasFeedback
            >
              <DatePicker
                placeholder='Selecciona una fecha'
                style={{ minWidth: '100%' }}
                format='DD/MM/YYYY'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={12}
            xl={8}
          >
            <Form.Item
              label='Tipo de servicio'
              name='type'
              rules={[
                {
                  required: true,
                  message: 'No olvides el tipo de servicio'
                }
              ]}
              required
              hasFeedback
            >
              <Select
                placeholder='Selecciona un servicio'
                options={SERVICES}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={12}
            xl={8}
          >
            <Form.Item
              label='Identificador de contrato'
              name='contractId'
              rules={[
                {
                  required: true,
                  message: 'No olvides el identificador'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='XA-0000'
                type='text'
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Datos del contratante</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            xl={16}
          >
            <Form.Item
              label='Cliente'
              name='customer'
              rules={[
                {
                  required: true,
                  message: 'Necesitas seleccionar un cliente'
                }
              ]}
              required
              hasFeedback
            >
              <Select
                allowClear
                showSearch
                placeholder='Nombre del cliente a buscar'
                defaultActiveFirstOption={false}
                suffixIcon={<SearchOutlined />}
                filterOption={false}
                onSearch={handleSearch}
                options={results.map((customer) => ({
                  value: customer.value,
                  label: <span className={style.option} dangerouslySetInnerHTML={{ __html: customer.label }} />
                }))}
                notFoundContent={null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Text>¿No encuentras al cliente en esta lista?</Text> <Link to='/app/customers/add'>agrégalo aquí</Link>
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Disponibilidad del servicio</Title>
          <Text strong>¿El servicio contratado está pensado a futuro?</Text>
        </Form.Item>
        <Form.Item
          name='future'
        >
          <Radio.Group onChange={handleRadioChange} name='future'>
            <Space direction='vertical' size='middle'>
              <Radio value='yes'>Si, el servicio estará disponible para su uso posterior.</Radio>
              <Radio value='no'>No, quiero capturar los datos de la persona fallecida que requerirá el servicio.</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {showDeceasedForm && (
          <>
            <Form.Item>
              <Title level={4} className={style.title}>Datos del finado</Title>
            </Form.Item>
            <DeceasedForm />
          </>
        )}
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Especificaciones</Title>
        </Form.Item>
        <Form.Item
          name='specs'
          label='Elegir servicios'
          help='Selecciona los servicios que se incluirán en este paquete. Una vez seleccionados podrás definir los costos y características de cada uno.'
          rules={[
            {
              required: true,
              message: 'Necesitas especificar los servicios requeridos'
            }
          ]}
          required
        >
          <Select
            mode='multiple'
            allowClear
            style={{ width: '100%' }}
            placeholder='Elige el ó los servicios incluidos'
            options={SERVICES_SPECS}
            onChange={handleSelectChange}
          />
        </Form.Item>
        <Divider style={{ marginTop: '3rem' }} />
        {itemsSpecs.map((item, index) => {
          return <Item key={'item' + index} value={item} handleAddition={handleAddition} />
        })}
        <Form.Item>
          <Title level={4} className={style.title}>Costo total</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={12}
            xl={8}
          >
            <Form.Item
              name='totalCost'
              label='Monto'
              required
            >
              <InputNumber
                placeholder='0.00'
                addonBefore='$'
                style={{ width: '100%' }}
                formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                readOnly
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Observaciones</Title>
          <Text>Comentarios adicionales o requerimientos especiales del servicio.</Text>
        </Form.Item>
        <Form.Item
          name='notes'
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 8 }}
          />
        </Form.Item>
      </Section>
      <Section
        className={style.actions}
        marginV
      >
        <Form.Item noStyle>
          <Button
            type='primary'
            htmlType='submit'
            loading={isSaving}
          >
            Guardar servicio
          </Button>
        </Form.Item>
      </Section>
    </Form>
  )
}

export default Add
