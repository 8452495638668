import { useState, useCallback } from 'react'

import useUser from './useUser'
import { apiUsers } from '../api'

const useUpdateEmail = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [, dispatch] = useUser()

  const updateEmail = useCallback((email) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      apiUsers.updateEmail(email)
        .then(() => {
          dispatch({
            type: 'change_email',
            value: email
          })
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/email-already-in-use':
              error.field = 'email'
              error.message = 'No se puede utilizar este email, pertenece a otro usuario.'
              break
            case 'auth/requires-recent-login':
              return resolve(true)
            default:
              error.message = err.message
              break
          }
          reject(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [dispatch])

  return { updateEmail, isLoading }
}

export default useUpdateEmail
