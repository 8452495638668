import { useState, useCallback } from 'react'

import useUser from './useUser'
import { apiUsers } from '../api'

const useUpdateName = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [, dispatch] = useUser()

  const updateName = useCallback((value) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      apiUsers.updateProfile(value)
        .then(() => {
          dispatch({
            type: 'change_name',
            value
          })
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [dispatch])

  return { updateName, isLoading }
}

export default useUpdateName
