import useSWR from 'swr'
import { useMemo } from 'react'

import { apiServices, apiCustomers } from '../api'

const useService = (id) => {
  const service = useSWR(id ? ['getService', id] : null, () => apiServices.get(id))
  const customer = useSWR(() => ['getCustomer', service.data.customer], () => apiCustomers.get(service.data.customer))

  const error = service.error || customer.error
  const isLoading = !(!service.isLoading && !customer.isLoading)

  const data = useMemo(() => {
    if (!isLoading && !error) {
      if (service.data) {
        return {
          ...service.data,
          id,
          customerName: `${customer.data?.name} ${customer.data?.lastName}${customer.data.sLastName ? ` ${customer.data.sLastName}` : ''}`,
          customerTel: customer.data?.tel
        }
      }
      return null
    }
  }, [error, isLoading, service.data, customer.data, id])

  return { data, error, isLoading }
}

export default useService
