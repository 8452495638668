import style from './Customer.module.scss'
import ESTADOS_MEXICO from './estados-mx'

import { Button, Form, Input, Select, Typography, Col, Row, message } from 'antd'

import Section from '../../commons/Section'

import useAddCustomer from '../../../hooks/useAddCustomer'

const { Title } = Typography

const Add = ({ onSuccess }) => {
  const [form] = Form.useForm()
  const { addCustomer, isSaving } = useAddCustomer()
  const [messageApi, contextHolder] = message.useMessage()

  const handleFinish = (values) => {
    addCustomer(values)
      .then((id) => {
        onSuccess(id)
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  return (
    <Form
      name='customer'
      form={form}
      layout='vertical'
      onFinish={handleFinish}
      autoComplete='off'
      className={style.container}
      scrollToFirstError
    >
      {contextHolder}
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Contratante</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'No olvides el nombre'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Nombre(s)'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido paterno'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el apellido'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido materno'
              name='sLastName'
              hasFeedback
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 16, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Domicilio'
              name='address'
              hasFeedback
            >
              <Input
                placeholder='Calle, número, colonia'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Estado'
              name='state'
              rules={[
                {
                  required: true,
                  message: 'Necesitas seleccionar un estado'
                }
              ]}
              initialValue='Sinaloa'
              required
              hasFeedback
            >
              <Select
                placeholder='Selecciona un estado'
                options={ESTADOS_MEXICO}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 8, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Ciudad'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'No olvides la ciudad'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Ciudad'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Teléfono'
              name='tel'
              rules={[
                {
                  required: true,
                  message: 'No olvides el teléfono'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='999 999 9999'
                type='tel'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Introduce una cuenta de correo válida'
                }
              ]}
              hasFeedback
            >
              <Input
                placeholder='cuenta@correo.com'
                type='email'
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Referente asociado</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Nombre'
              name='secName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el nombre'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Nombre(s)'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido paterno'
              name='secLastName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el apellido'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido materno'
              name='secSLastName'
              hasFeedback
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 16, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Domicilio'
              name='secAddress'
              hasFeedback
            >
              <Input
                placeholder='Calle, número, colonia'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Estado'
              name='secState'
              rules={[
                {
                  required: true,
                  message: 'Necesitas seleccionar un estado'
                }
              ]}
              initialValue='Sinaloa'
              required
              hasFeedback
            >
              <Select
                placeholder='Selecciona un estado'
                options={ESTADOS_MEXICO}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 8, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Ciudad'
              name='secCity'
              rules={[
                {
                  required: true,
                  message: 'No olvides la ciudad'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='Ciudad'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Teléfono'
              name='secTel'
              rules={[
                {
                  required: true,
                  message: 'No olvides el teléfono'
                }
              ]}
              required
              hasFeedback
            >
              <Input
                placeholder='999 999 9999'
                type='tel'
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section
        className={style.actions}
        marginV
      >
        <Form.Item noStyle>
          <Button
            type='primary'
            htmlType='submit'
            loading={isSaving}
          >
            Guardar cliente
          </Button>
        </Form.Item>
      </Section>
    </Form>
  )
}

export default Add
