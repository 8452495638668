import { useRef } from 'react'
import { Form, Input, Modal, Typography, message } from 'antd'

import useReauthenticate from '../../hooks/useReauthenticate'

const { Text } = Typography

const Reauthenticate = ({ show, close, onReauth }) => {
  const [form] = Form.useForm()
  const { reauthenticate, isReauthenticating } = useReauthenticate()
  const [messageApi, contextHolder] = message.useMessage()
  const inputRef = useRef(null)

  const handleSubmit = ({ password }) => {
    reauthenticate(password)
      .then(() => {
        onReauth()
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  return (
    <Modal
      title='Introduce tu contraseña'
      open={show}
      afterOpenChange={(value) => value && inputRef.current.focus()}
      onOk={() => form.submit()}
      onCancel={close}
      okText='Aceptar'
      cancelText='Cancelar'
      confirmLoading={isReauthenticating}
      centered
      destroyOnClose
    >
      {contextHolder}
      <div style={{ marginBottom: '1rem' }}>
        <Text>Para efectuar este cambio es necesario que proporciones tu contraseña actual.</Text>
      </div>
      <Form
        name='reauth'
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        autoComplete='off'
      >
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Introduce tu contraseña'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input.Password
            placeholder='Password'
            ref={inputRef}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default Reauthenticate
