import { useContext } from 'react'

import { UserContext } from '../context/User'

const useUser = () => {
  const [user, dispatch] = useContext(UserContext)

  return [user, dispatch]
}

export default useUser
