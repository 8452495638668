import style from './Auth.module.scss'

import { Button, Checkbox, Form, Input, Typography, Row, Col, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import useSignIn from '../../../hooks/useSignIn'

const { Text } = Typography

const Login = () => {
  const [form] = Form.useForm()
  const { signIn, isSigning } = useSignIn()
  const [messageApi, contextHolder] = message.useMessage()

  const onFinish = (values) => {
    signIn(values)
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  return (
    <>
      {contextHolder}
      <Form
        name='login'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        autoComplete='off'
      >
        <div className={style.header}>
          <Text>Inicia sesión para comenzar.</Text>
        </div>
        <Form.Item
          label='Usuario'
          name='email'
          rules={[
            {
              required: true,
              message: 'Tu usuario es tu cuenta de correo'
            },
            {
              type: 'email',
              message: 'Introduce una cuenta de correo válida'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input
            placeholder='correo@electronico'
            type='email'
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item
          label='Contraseña'
          name='password'
          rules={[
            {
              required: true,
              message: 'Introduce tu contraseña'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined />}
          />
        </Form.Item>
        <Row
          justify='space-between'
        >
          <Col>
            <Form.Item
              name='remember'
              valuePropName='checked'
              initialValue
            >
              <Checkbox>Recordarme</Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Link to='/auth/reset-password'>¿Olvidaste tu contraseña?</Link>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item noStyle>
          <Button
            type='primary'
            htmlType='submit'
            block
            loading={isSigning}
          >
            Iniciar sesión
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default Login
