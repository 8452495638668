import { useCallback, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Button, Table, message } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'

import Section from '../commons/Section'
import Action from '../commons/Action'
import Search from '../modules/Search'

import useNavigation from '../../hooks/useNavigation'
import useCustomers from '../../hooks/useCustomers'

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    render: (value, record) => <Link to={`/app/customers/edit/${record.id}`}>{value}</Link>,
    sorter: (a, b) => a.name?.localeCompare(b.name)
  },
  {
    title: 'Teléfono',
    dataIndex: 'tel',
    key: 'tel',
    responsive: ['md']
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
    responsive: ['lg'],
    ellipsis: true,
    sorter: (a, b) => a.state?.localeCompare(b.state)
  },
  {
    title: 'Ciudad',
    dataIndex: 'city',
    key: 'city',
    responsive: ['xl'],
    ellipsis: true
  }
]

const Customers = () => {
  const navigate = useNavigate()
  const { setNavigation } = useNavigation()
  const { data: customers, error, isLoading } = useCustomers()
  const [messageApi, contextHolder] = message.useMessage()
  const [searchResults, setSearchResults] = useState(null)

  const handleClick = useCallback(() => {
    navigate('/app/customers/add')
  }, [navigate])

  const handleSearch = (results) => {
    setSearchResults(results)
  }

  useEffect(() => {
    setNavigation({
      title: 'Clientes',
      action: <Button type='primary' icon={<UserAddOutlined />} onClick={handleClick}>Nuevo cliente</Button>
    })
  }, [setNavigation, handleClick])

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [error, messageApi])

  return (
    <>
      {contextHolder}
      <Search
        placeholder='Nombre o teléfono del cliente'
        onSearch={handleSearch}
        data={customers}
      />
      <Section marginH>
        <Table
          dataSource={searchResults || customers}
          columns={columns}
          size='small'
          loading={isLoading}
          pagination={{
            defaultPageSize: 50
          }}
        />
      </Section>
      <Action
        icon={<UserAddOutlined />}
        tooltip='Nuevo cliente'
        onClick={handleClick}
      />
    </>
  )
}

export default Customers
