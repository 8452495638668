import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Switch, Typography, Space, message } from 'antd'

import Edit from '../modules/Customer/Edit'
import Result from '../modules/Result'
import Loader from '../commons/Loader'

import useNavigation from '../../hooks/useNavigation'
import useCustomer from '../../hooks/useCustomer'

const { Text } = Typography

const EditAdmin = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { setNavigation } = useNavigation()
  const { data: customer, error, isLoading } = useCustomer(id)
  const [messageApi, contextHolder] = message.useMessage()
  const [showResult, setShowResult] = useState(false)
  const [editable, setEditable] = useState(false)

  useEffect(() => {
    setNavigation({
      title: 'Consultar cliente',
      items: [
        { title: <Link to='/app/customers'>Clientes</Link> },
        { title: 'Consultar cliente' }
      ],
      hasBack: true,
      action: !isLoading && !customer ? null : <Space><Text type='secondary'>Editar</Text><Switch onChange={(checked) => setEditable(checked)} /></Space>
    })
  }, [setNavigation, isLoading, customer])

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [error, messageApi])

  if (isLoading) {
    return <Loader />
  } else if (!isLoading && !customer) {
    return (
      <>
        {contextHolder}
        <Result
          status='404'
          title='El cliente no existe'
          subTitle='Este registro no pertenece a ningún cliente de la plataforma.'
          extra={<Button type='primary' onClick={() => navigate('/app/customers')}>Regresar a clientes</Button>}
        />
      </>
    )
  } else {
    return (
      <>
        {contextHolder}
        {showResult
          ? <Result
              status='success'
              title='El cliente ha sido eliminado correctamente'
              subTitle='Sus registros han sido dados de baja de la plataforma.'
              extra={<Button type='primary' onClick={() => navigate('/app/customers')}>Regresar a clientes</Button>}
            />
          : <Edit
              customer={customer}
              disabled={!editable}
              onSuccess={() => setShowResult(true)}
            />}
      </>
    )
  }
}

export default EditAdmin
