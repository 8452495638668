import { Routes, Route, Navigate } from 'react-router-dom'

import Auth from './layout/Auth'
import Dashboard from './layout/Dashboard'

import Login from './routes/Login'
import Customers from './routes/Customers'
import AddCustomer from './routes/AddCustomer'
import EditCustomer from './routes/EditCustomer'
import Services from './routes/Services'
import AddService from './routes/AddService'
import EditService from './routes/EditService'
import ResetPassword from './routes/ResetPassword'
import EditProfile from './routes/EditProfile'
import ChangePassword from './routes/ChangePassword'
import ChangeEmail from './routes/ChangeEmail'
import Error from './routes/Error'

import { UserProvider } from '../context/User'
import { NavigationProvider } from '../context/Navigation'
import useUser from '../hooks/useUser'

const App = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path='auth' element={<NotAuth><Auth /></NotAuth>}>
          <Route index element={<Navigate to='login' />} />
          <Route path='login' element={<Login />} />
          <Route path='reset-password' element={<ResetPassword />} />
        </Route>
        <Route path='app' element={<RequireAuth><Dashboard /></RequireAuth>}>
          <Route index element={<Navigate to='customers' />} />
          <Route path='customers'>
            <Route index element={<Customers />} />
            <Route path='add' element={<AddCustomer />} />
            <Route path='edit/:id' element={<EditCustomer />} />
          </Route>
          <Route path='services'>
            <Route index element={<Services />} />
            <Route path='add' element={<AddService />} />
            <Route path='edit/:id' element={<EditService />} />
          </Route>
          <Route path='settings'>
            <Route index element={<Navigate to='edit-profile' />} />
            <Route path='edit-profile' element={<EditProfile />} />
            <Route path='change-password' element={<ChangePassword />} />
            <Route path='change-email' element={<ChangeEmail />} />
          </Route>
        </Route>
        <Route path='/' element={<Navigate to='/app/customers' replace />} />
        <Route
          path='*'
          element={
            <RequireAuth>
              <Error
                status={404}
                title='¿Cómo llegaste aquí?'
                subTitle='La sección que buscas no existe.'
              />
            </RequireAuth>
          }
        />
      </Routes>
    </UserProvider>
  )
}

const NotAuth = ({ children }) => {
  const [user] = useUser()

  if (user) {
    return <Navigate to='/app' replace />
  }

  return children
}

const RequireAuth = ({ children }) => {
  const [user] = useUser()

  if (!user) {
    return <Navigate to='/auth/login' />
  }

  return <NavigationProvider>{children}</NavigationProvider>
}

export default App
