import { useState, useCallback } from 'react'

import { apiAuth } from '../api'

const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false)

  const resetPassword = useCallback((email) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      apiAuth.resetPassword(email)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              error.field = 'email'
              error.message = 'Esta cuenta no pertenece a ningún usuario.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [])

  return { resetPassword, isLoading }
}

export default useResetPassword
