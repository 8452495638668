import useSWR from 'swr'
import { useMemo } from 'react'

import { apiCustomers } from '../api'

const useCustomer = (id) => {
  const customer = useSWR(id ? ['getCustomer', id] : null, () => apiCustomers.get(id))
  const services = useSWR(id ? ['getCustomerServices', id] : null, () => apiCustomers.getServices(id))

  const error = customer.error || services.error
  const isLoading = !(!customer.isLoading && !services.isLoading)

  const data = useMemo(() => {
    if (!isLoading && !error) {
      if (customer.data) {
        return {
          ...customer.data,
          id,
          services: services.data.map((service) => ({ key: service.id, ...service }))
        }
      }
      return null
    }
  }, [error, isLoading, customer.data, services.data, id])

  return { data, error, isLoading }
}

export default useCustomer
