import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Space } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

import Add from '../modules/Service/Add'
import Result from '../modules/Result'

import useNavigation from '../../hooks/useNavigation'

const AddService = () => {
  const [showResult, setShowResult] = useState(false)
  const navigate = useNavigate()
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Nuevo servicio',
      items: [
        { title: <Link to='/app/services'>Servicios</Link> },
        { title: 'Agregar servicio' }
      ],
      hasBack: true
    })
  }, [setNavigation])

  return (
    <>
      {showResult
        ? <Result
            status='success'
            title='El servicio ha sido registrado'
            subTitle='¿Qué deseas hacer ahora?'
            extra={
              <Space size={[8, 16]} wrap>
                <Button key='contract' icon={<DownloadOutlined />}>Generar contrato</Button> {/* TODO: Agregar ruta de documento */}
                <Button key='bill' icon={<DownloadOutlined />}>Generar presupuesto</Button> {/* TODO: Agregar ruta de documento */}
                <Button type='primary' key='back' onClick={() => navigate('/app/services')}>Regresar a servicios</Button>
              </Space>
            }
          />
        : <Add
            onSuccess={() => setShowResult(true)}
          />}
    </>
  )
}

export default AddService
