import { SERVICES } from '../modules/Service/services'

import { useCallback, useEffect, useState } from 'react'
import { Button, Table, message, Tag } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'
import { useNavigate, Link } from 'react-router-dom'

import Section from '../commons/Section'
import Action from '../commons/Action'
import Search from '../modules/Search'

import useNavigation from '../../hooks/useNavigation'
import useServices from '../../hooks/useServices'

const columns = [
  {
    title: 'Identificador',
    dataIndex: 'contractId',
    key: 'contractId',
    render: (value, record) => <Link to={`/app/services/edit/${record.id}`}>{value}</Link>
  },
  {
    title: 'Tipo de servicio',
    dataIndex: 'type',
    key: 'type',
    filters: SERVICES.map(service => ({ text: service.value, value: service.value })),
    onFilter: (value, record) => record.type.indexOf(value) === 0,
    responsive: ['md'],
    ellipsis: true
  },
  {
    title: 'Cliente',
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name?.localeCompare(b.name),
    ellipsis: true
  },
  {
    title: 'Disponibilidad',
    dataIndex: 'future',
    key: 'future',
    responsive: ['xl'],
    render: (value) => {
      let label = value
      let color = 'default'
      if (value === 'yes') {
        label = 'Disponible'
        color = 'green'
      } else if (value === 'no') {
        label = 'Utilizado'
      }
      return <Tag color={color}>{label}</Tag>
    }
  }
]

const Services = () => {
  const navigate = useNavigate()
  const { setNavigation } = useNavigation()
  const { data: services, error, isLoading } = useServices()
  const [messageApi, contextHolder] = message.useMessage()
  const [searchResults, setSearchResults] = useState(null)

  const handleClick = useCallback(() => {
    navigate('/app/services/add')
  }, [navigate])

  const handleSearch = (results) => {
    setSearchResults(results)
  }

  useEffect(() => {
    setNavigation({
      title: 'Servicios',
      action: <Button type='primary' icon={<FileAddOutlined />} onClick={handleClick}>Nuevo servicio</Button>
    })
  }, [setNavigation, handleClick])

  useEffect(() => {
    if (error) {
      messageApi.open({
        type: 'error',
        content: error.message
      })
    }
  }, [error, messageApi])

  return (
    <>
      {contextHolder}
      <Search
        placeholder='Nombre del finado, nombre del cliente o identificador de contrato'
        onSearch={handleSearch}
        data={services}
      />
      <Section marginH>
        <Table
          dataSource={searchResults || services}
          columns={columns}
          size='small'
          loading={isLoading}
          pagination={{
            defaultPageSize: 50
          }}
        />
      </Section>
      <Action
        icon={<FileAddOutlined />}
        tooltip='Nuevo servicio'
        onClick={handleClick}
      />
    </>
  )
}

export default Services
