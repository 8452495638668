import { Result } from 'antd'

import Section from '../commons/Section'

const ResultModule = ({ status, title, subTitle, extra }) => {
  return (
    <Section marginH>
      <Result
        status={status}
        title={title}
        subTitle={subTitle}
        extra={extra}
      />
    </Section>
  )
}

export default ResultModule
