const setUnits = (num) => {
  switch (num) {
    case 1:
      return 'UN'
    case 2:
      return 'DOS'
    case 3:
      return 'TRES'
    case 4:
      return 'CUATRO'
    case 5:
      return 'CINCO'
    case 6:
      return 'SEIS'
    case 7:
      return 'SIETE'
    case 8:
      return 'OCHO'
    case 9:
      return 'NUEVE'
    default:
      return ''
  }
}

const setTens = (num) => {
  const ten = Math.floor(num / 10)
  const unit = num - (ten * 10)

  switch (ten) {
    case 1:
      switch (unit) {
        case 0:
          return 'DIEZ'
        case 1:
          return 'ONCE'
        case 2:
          return 'DOCE'
        case 3:
          return 'TRECE'
        case 4:
          return 'CATORCE'
        case 5:
          return 'QUINCE'
        default:
          return 'DIECI' + setUnits(unit)
      }
    case 2:
      switch (unit) {
        case 0:
          return 'VEINTE'
        default:
          return 'VEINTI' + setUnits(unit)
      }
    case 3:
      return setTensAnd('TREINTA', unit)
    case 4:
      return setTensAnd('CUARENTA', unit)
    case 5:
      return setTensAnd('CINCUENTA', unit)
    case 6:
      return setTensAnd('SESENTA', unit)
    case 7:
      return setTensAnd('SETENTA', unit)
    case 8:
      return setTensAnd('OCHENTA', unit)
    case 9:
      return setTensAnd('NOVENTA', unit)
    case 0:
      return setUnits(unit)
    default:
      return ''
  }
}

const setTensAnd = (strSin, numUnits) => {
  if (numUnits > 0) return strSin + ' Y ' + setUnits(numUnits)
  return strSin
}

const setHundreds = (num) => {
  const hundreds = Math.floor(num / 100)
  const tens = num - (hundreds * 100)

  switch (hundreds) {
    case 1:
      if (tens > 0) return 'CIENTO ' + setTens(tens)
      return 'CIEN'
    case 2:
      return 'DOSCIENTOS ' + setTens(tens)
    case 3:
      return 'TRESCIENTOS ' + setTens(tens)
    case 4:
      return 'CUATROCIENTOS ' + setTens(tens)
    case 5:
      return 'QUINIENTOS ' + setTens(tens)
    case 6:
      return 'SEISCIENTOS ' + setTens(tens)
    case 7:
      return 'SETECIENTOS ' + setTens(tens)
    case 8:
      return 'OCHOCIENTOS ' + setTens(tens)
    case 9:
      return 'NOVECIENTOS ' + setTens(tens)
    default:
      return setTens(tens)
  }
}

const setSection = (num, divider, strSingular, strPlural) => {
  const hundreds = Math.floor(num / divider)
  const rest = num - (hundreds * divider)

  let words = ''

  if (hundreds > 0) {
    if (hundreds > 1) {
      words = setHundreds(hundreds) + ' ' + strPlural
    } else {
      words = strSingular
    }
  }

  if (rest > 0) words += ''

  return words
}

const setThousands = (num) => {
  const divider = 1000
  const hundreds = Math.floor(num / divider)
  const rest = num - (hundreds * divider)

  const strThousands = setSection(num, divider, 'UN MIL', 'MIL')
  const strHundreds = setHundreds(rest)

  if (strThousands === '') return strHundreds

  return strThousands + ' ' + strHundreds
}

const setMillions = (num) => {
  const divider = 1000000
  const hundreds = Math.floor(num / divider)
  const rest = num - (hundreds * divider)

  const strMillions = setSection(num, divider, 'UN MILLON DE', 'MILLONES DE')
  const strThousands = setThousands(rest)

  if (strMillions === '') return strThousands

  return strMillions + ' ' + strThousands
}

const currencyToWords = (num) => {
  const data = {
    number: num,
    integer: Math.floor(num),
    cents: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
    wordSectionCents: '',
    wordCurrencyPlural: 'PESOS',
    wordCurrencySingular: 'PESO',
    wordCentsPlural: 'CENTAVOS',
    wordCentsSingular: 'CENTAVO'
  }

  if (data.cents > 0) {
    data.wordSectionCents = 'CON ' + (function () {
      if (data.cents === 1) return setMillions(data.cents) + ' ' + data.wordCentsSingular
      else return setMillions(data.cents) + ' ' + data.wordCentsPlural
    })()
  }

  if (data.integer === 0) return 'CERO ' + data.wordCurrencyPlural + ' ' + data.wordSectionCents
  if (data.integer === 1) return setMillions(data.integer) + ' ' + data.wordCurrencySingular + ' ' + data.wordSectionCents
  else return setMillions(data.integer) + ' ' + data.wordCurrencyPlural + ' ' + data.wordSectionCents
}

export default currencyToWords
