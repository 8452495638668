import style from './Settings.module.scss'

import { useEffect, useState } from 'react'
import { Button, Form, Input, message } from 'antd'

import Section from '../../commons/Section'
import Reauthenticate from '../Reauthenticate'

import useUser from '../../../hooks/useUser'
import useUpdateEmail from '../../../hooks/useUpdateEmail'

const Email = () => {
  const [form] = Form.useForm()
  const [user] = useUser()
  const { updateEmail, isLoading } = useUpdateEmail()
  const [messageApi, contextHolder] = message.useMessage()
  const [showReauthentication, setShowReauthentication] = useState(false)

  useEffect(() => {
    const { email } = user
    form.setFieldsValue({ email })
  }, [form, user])

  const handleFinish = ({ email }) => {
    updateEmail(email)
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setShowReauthentication(true)
          return
        }
        messageApi.open({
          type: 'success',
          content: 'Tu email ha sido modificado correctamente.'
        })
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  const handleReauth = () => {
    setShowReauthentication(false)
    const email = form.getFieldValue('email')
    updateEmail(email)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Tu email ha sido actualizado correctamente.'
        })
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  return (
    <Section marginH>
      {contextHolder}
      <Form
        name='email'
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        autoComplete='off'
        wrapperCol={{
          xs: { span: 24 },
          lg: { span: 12 }
        }}
      >
        <Form.Item
          label='Correo electrónico'
          name='email'
          rules={[
            {
              required: true,
              message: 'Tu cuenta de email no puede quedar vacia'
            },
            {
              type: 'email',
              message: 'Introduce una cuenta de correo válida'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input
            placeholder='correo@electronico'
            type='email'
          />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            className={style.submit}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Guardar email
          </Button>
        </Form.Item>
      </Form>
      <Reauthenticate
        show={showReauthentication}
        close={() => setShowReauthentication(false)}
        onReauth={handleReauth}
      />
    </Section>
  )
}

export default Email
