import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'

import useNavigation from '../../hooks/useNavigation'

const Error = ({ status, title, subTitle }) => {
  const navigate = useNavigate()
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Error ' + status,
      hasBack: true
    })
  }, [setNavigation, status])

  return (
    <Result
      status={status}
      title={title}
      subTitle={subTitle}
      extra={<Button type='primary' onClick={() => navigate('/app/customers')}>Volver al inicio</Button>}
    />
  )
}

export default Error
