import style from './Service.module.scss'
import { SERVICES_SPECS } from './services'

import { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Col, Row, Typography, Divider } from 'antd'
import debounce from 'lodash/debounce'

const { Title } = Typography

const Item = ({ value, handleAddition }) => {
  const [title, setTitle] = useState('')

  const handleChange = debounce((value) => handleAddition(value), 800)

  useEffect(() => {
    const service = SERVICES_SPECS.find((item) => item.value === value)
    setTitle(service.label)
  }, [value])

  return (
    <>
      <Row gutter={24}>
        <Col
          xs={24}
        >
          <Form.Item>
            <Title level={4} className={style.title}>{title}</Title>
          </Form.Item>
        </Col>
        <Col
          xs={24}
          lg={12}
          xl={8}
        >
          <Form.Item
            label='Costo'
            name={`specs_${value}_cost`}
            rules={[
              {
                required: true,
                message: 'No olvides el costo del servicio'
              }
            ]}
            required
          >
            <InputNumber
              placeholder='0.00'
              addonBefore='$'
              style={{ width: '100%' }}
              formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          lg={12}
        >
          <Form.Item
            label='Detalles'
            name={`specs_${value}_details`}
            hasFeedback
          >
            <Input
              placeholder='Detalles opcionales del servicio'
              type='text'
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default Item
