import style from './Service.module.scss'
import { SERVICES, SERVICES_SPECS } from './services'

import { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input, Select, Typography, Col, Row, message, DatePicker, Radio, Space, Divider, InputNumber, Popconfirm } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'

import Section from '../../commons/Section'
import DeceasedForm from './DeceasedForm'
import Item from './Item'

import useUpdateService from '../../../hooks/useUpdateService'
import useDeleteService from '../../../hooks/useDeleteService'
import useGeneratePDF from '../../../hooks/useGeneratePDF'

const { Title, Text } = Typography

const Edit = ({ service, onSuccess, disabled }) => {
  const [form] = Form.useForm()
  const { updateService, isUpdating } = useUpdateService()
  const { deleteService, isDeleting } = useDeleteService()
  const { generatePDF, isCreating } = useGeneratePDF()
  const [messageApi, contextHolder] = message.useMessage()
  const [showDeceasedForm, setShowDeceasedForm] = useState(false)
  const [itemsSpecs, setItemsSpecs] = useState([])

  const handleFinish = (fieldsValue) => {
    const values = {
      ...fieldsValue,
      date: fieldsValue.date?.format('YYYY-MM-DD'),
      deceasedDate: fieldsValue.deceasedDate?.format('YYYY-MM-DD'),
      customerName: undefined,
      customerTel: undefined
    }
    updateService(values, service.id)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Los cambios han sido guardados correctamente.'
        })
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  const handleConfirm = () => {
    deleteService(service.id)
      .then(() => {
        onSuccess()
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  const handleRadioChange = (ev) => {
    const value = ev.target.value
    if (value === 'yes') {
      setShowDeceasedForm(false)
    } else {
      setShowDeceasedForm(true)
    }
  }

  const handleSelectChange = (value) => {
    setItemsSpecs(value)
  }

  const handleAddition = useCallback((value) => {
    if (value !== null) {
      let addition = 0
      itemsSpecs.forEach((item) => {
        const itemCost = form.getFieldValue('specs_' + item + '_cost')
        if (isNaN(itemCost)) return
        addition += itemCost
      })
      form.setFieldValue('totalCost', addition)
    }
  }, [form, itemsSpecs])

  useEffect(() => {
    if (service) handleAddition(0)
  }, [itemsSpecs, handleAddition, service])

  useEffect(() => {
    if (service) {
      const isFuture = service.future === 'yes'
      if (isFuture) {
        setShowDeceasedForm(false)
      } else {
        setShowDeceasedForm(true)
      }

      const specs = service.specs || []
      setItemsSpecs(specs)

      const fieldsValue = {
        ...service,
        type: SERVICES.find((type) => type.value === service.type)?.value,
        date: service.date ? dayjs(service.date, 'YYYY-MM-DD') : null,
        deceasedDate: service.deceasedDate ? dayjs(service.deceasedDate, 'YYYY-MM-DD') : null
      }

      form.setFieldsValue(fieldsValue)
    }
  }, [service, form])

  return (
    <Form
      name='customer'
      form={form}
      layout='vertical'
      onFinish={handleFinish}
      autoComplete='off'
      className={style.container}
      disabled={disabled || isDeleting}
      requiredMark={!disabled}
      scrollToFirstError
    >
      {contextHolder}
      <Row gutter={24}>
        <Col
          xs={24}
          xl={18}
        >
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Detalles del servicio</Title>
            </Form.Item>
            <Row gutter={24}>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                <Form.Item
                  label='Fecha de contratación'
                  name='date'
                  rules={[
                    {
                      required: true,
                      message: 'Necesitas seleccionar una fecha'
                    }
                  ]}
                  required
                >
                  <DatePicker
                    placeholder='Selecciona una fecha'
                    style={{ minWidth: '100%' }}
                    format='DD/MM/YYYY'
                  />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                {disabled
                  ? (
                    <Form.Item
                      label='Tipo de servicio'
                      name='type'
                    >
                      <Input
                        type='text'
                      />
                    </Form.Item>
                    )
                  : (
                    <Form.Item
                      label='Tipo de servicio'
                      name='type'
                      rules={[
                        {
                          required: true,
                          message: 'No olvides el tipo de servicio'
                        }
                      ]}
                      required
                    >
                      <Select
                        placeholder='Selecciona un servicio'
                        options={SERVICES}
                      />
                    </Form.Item>
                    )}
              </Col>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                <Form.Item
                  label='Identificador de contrato'
                  name='contractId'
                  rules={[
                    {
                      required: true,
                      message: 'No olvides el identificador'
                    }
                  ]}
                  required
                >
                  <Input
                    placeholder='XA-0000'
                    type='text'
                  />
                </Form.Item>
              </Col>
            </Row>
          </Section>
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Datos del contratante</Title>
            </Form.Item>
            <Row gutter={24}>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                <Form.Item
                  label='Nombre'
                  name='customerName'
                >
                  <Input type='text' disabled />
                </Form.Item>
              </Col>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                <Form.Item
                  label='Teléfono'
                  name='customerTel'
                >
                  <Input type='text' disabled />
                </Form.Item>
              </Col>
            </Row>
            <Text type='secondary'>El contratante no puede ser cambiado, este fue elegido al momento de crear el servicio. </Text>
            <Link to={`/app/customers/edit/${service.customer}`}>Ver más información del cliente</Link>
          </Section>
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Disponibilidad del servicio</Title>
              <Text strong>¿El servicio contratado está pensado a futuro?</Text>
            </Form.Item>
            <Form.Item
              name='future'
            >
              <Radio.Group onChange={handleRadioChange} name='future'>
                <Space direction='vertical' size='middle'>
                  <Radio value='yes'>Si, el servicio estará disponible para su uso posterior.</Radio>
                  <Radio value='no'>No, quiero capturar los datos de la persona fallecida que requerirá el servicio.</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {showDeceasedForm && (
              <>
                <Form.Item>
                  <Title level={4} className={style.title}>Datos del finado</Title>
                </Form.Item>
                <DeceasedForm />
              </>
            )}
          </Section>
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Especificaciones</Title>
            </Form.Item>
            <Form.Item
              name='specs'
              label='Elegir servicios'
              help='Selecciona los servicios que se incluirán en este paquete. Una vez seleccionados podrás definir los costos y características de cada uno.'
              rules={[
                {
                  required: true,
                  message: 'Necesitas especificar los servicios requeridos'
                }
              ]}
              required
            >
              <Select
                mode='multiple'
                allowClear
                style={{ width: '100%' }}
                placeholder='Elige el ó los servicios incluidos'
                options={SERVICES_SPECS}
                onChange={handleSelectChange}
              />
            </Form.Item>
            <Divider style={{ marginTop: '3rem' }} />
            {itemsSpecs.map((item, index) => {
              return <Item key={'item' + index} value={item} handleAddition={handleAddition} />
            })}
            <Form.Item>
              <Title level={4} className={style.title}>Costo total</Title>
            </Form.Item>
            <Row gutter={24}>
              <Col
                xs={24}
                lg={12}
                xl={8}
              >
                <Form.Item
                  name='totalCost'
                  label='Monto'
                  required
                >
                  <InputNumber
                    placeholder='0.00'
                    addonBefore='$'
                    style={{ width: '100%' }}
                    formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
          </Section>
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Observaciones</Title>
              <Text>Comentarios adicionales o requerimientos especiales del servicio.</Text>
            </Form.Item>
            <Form.Item
              name='notes'
            >
              <Input.TextArea
                autoSize={{ minRows: 2, maxRows: 8 }}
              />
            </Form.Item>
          </Section>
        </Col>
        <Col
          xs={24}
          xl={6}
        >
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Documentos</Title>
              <Text>Descarga o genera los archivos listos para imprimir referentes a este servicio.</Text>
            </Form.Item>
            <Form.Item>
              <div className={style.space}>
                <Button
                  onClick={() => generatePDF(service)}
                  className={style.buttonDownload}
                  disabled={false}
                  icon={<DownloadOutlined />}
                  loading={isCreating}
                >
                  Generar contrato
                </Button>
                <Button
                  onClick={() => generatePDF(service, 'quote')}
                  className={style.buttonDownload}
                  disabled={false}
                  icon={<DownloadOutlined />}
                  loading={isCreating}
                >
                  Generar presupuesto
                </Button>
              </div>
            </Form.Item>
          </Section>
          {!disabled && (
            <Section
              className={style.actions}
            >
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isUpdating}
                >
                  Guardar cambios
                </Button>
              </Form.Item>
              <Form.Item noStyle>
                <Popconfirm
                  title='¿Estás seguro de querer eliminar este servicio?'
                  onConfirm={handleConfirm}
                  okText='Si, eliminar'
                  cancelText='No, cancelar'
                >
                  <Button
                    type='link'
                    danger
                    loading={isDeleting}
                  >
                    Cancelar servicio
                  </Button>
                </Popconfirm>
              </Form.Item>
            </Section>
          )}
        </Col>
      </Row>
    </Form>
  )
}

export default Edit
