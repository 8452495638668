import { useState, useCallback } from 'react'

import { apiUsers } from '../api'

const useUpdatePassword = () => {
  const [isLoading, setIsLoading] = useState(false)

  const updatePassword = useCallback((password) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true)
      apiUsers.updatePassword(password)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: ''
          }
          switch (err.code) {
            case 'auth/weak-password':
              error.message = 'Tu contraseña no cumple con las condiciones de seguridad.'
              break
            case 'auth/requires-recent-login':
              return resolve(true)
            default:
              error.message = err.message
              break
          }
          reject(error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }, [])

  return { updatePassword, isLoading }
}

export default useUpdatePassword
