const SERVICES = [
  { value: 'Plan previsor' },
  { value: 'Nicho para cenizas' },
  { value: 'Lote en Campo Santo' },
  { value: 'Lote privado' },
  { value: 'Uso inmediato' }
]

const SERVICES_SPECS = [
  { label: 'Ataúd o urna', value: 'ataud' },
  { label: 'Carroza', value: 'carroza' },
  { label: 'Cementerio', value: 'cementerio' },
  { label: 'Cremación', value: 'cremacion' },
  { label: 'Preservación', value: 'preservacion' },
  { label: 'Recolección', value: 'recoleccion' },
  { label: 'Traslado', value: 'traslado' },
  { label: 'Velación en domicilio o capilla', value: 'velacion' },
  { label: 'Otros gastos', value: 'otros' }
]

export { SERVICES, SERVICES_SPECS }
