import style from './Action.module.scss'

import { FloatButton } from 'antd'

const Action = ({ icon, onClick, tooltip, type = 'primary' }) => {
  return (
    <FloatButton
      type={type}
      icon={icon}
      onClick={onClick}
      tooltip={tooltip}
      className={style.container}
    />
  )
}

const ActionGroup = ({ children }) => {
  return (
    <FloatButton.Group
      shape='circle'
      className={style.group}
    >
      {children}
    </FloatButton.Group>
  )
}

Action.Group = ActionGroup

export default Action
