import style from './Navbar.module.scss'

import { useEffect, useState } from 'react'

import { Link } from 'react-router-dom'

const Navbar = ({ items, activeKeys }) => {
  const [navItems, setNavItems] = useState([])

  useEffect(() => {
    const itemsArray = [].concat(items)
    itemsArray.pop()
    setNavItems(itemsArray)
  }, [items])

  return (
    <nav className={style.container}>
      <ul className={style.content}>
        {navItems.map((item, i) => (
          <li key={'item-' + i} className={`${style.item} ${activeKeys.includes(item.key) ? style.active : ''}`}>
            <Link to={item.key}>
              {activeKeys.includes(item.key) ? item.icon_active : item.icon}
              <span>{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Navbar
