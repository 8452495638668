import { useState, useCallback } from 'react'
import algoliasearch from 'algoliasearch/lite'

const searchClient = algoliasearch('UMYTHAPQ4J', '96a3d7d8cae0191f46ab561c5b6795a7')
const index = searchClient.initIndex(process.env.NODE_ENV === 'development' ? 'dev_customers' : 'prod_customers')

const useSearchCustomers = () => {
  const [results, setResults] = useState([])

  const search = useCallback((value) => {
    const performSearch = async (value) => {
      try {
        const { hits } = await index.search(value, {
          hitsPerPage: 5
        })
        const results = hits.map((hit) => {
          const { objectID: value, _highlightResult } = hit
          const {
            name: { value: name },
            lastName: { value: lastName }
          } = _highlightResult
          const sLastName = _highlightResult.sLastName ? _highlightResult.sLastName.value : null
          const label = `${name} ${lastName}${sLastName ? ` ${sLastName}` : ''}`
          return { label, value }
        })
        setResults(results)
      } catch (err) {
        console.log(err)
      }
    }

    if (value === '') {
      setResults([])
    } else {
      performSearch(value)
    }
  }, [])

  return [results, search]
}

export default useSearchCustomers
