import {
  orderBy,
  serverTimestamp,
  deleteField
} from 'firebase/firestore'

import {
  updateProfile, updateEmail, updatePassword,
  signInWithEmailAndPassword, signOut,
  EmailAuthProvider, sendPasswordResetEmail,
  reauthenticateWithCredential,
  setPersistence, browserSessionPersistence
} from 'firebase/auth'

import apiProvider from './provider'
import { auth } from '../lib/firebase'

const apiAuth = {
  signIn: async function (email, password, remember) {
    await signInWithEmailAndPassword(auth, email, password)
    if (!remember) setPersistence(auth, browserSessionPersistence)
  },
  signOut: async function () {
    return await signOut(auth)
  },
  reauthenticate: async function (password) {
    const currentUser = auth.currentUser
    const credential = EmailAuthProvider.credential(currentUser.email, password)
    return await reauthenticateWithCredential(currentUser, credential)
  },
  resetPassword: async function (email) {
    return await sendPasswordResetEmail(auth, email)
  }
}

const apiUsers = {
  collection: 'users',
  get: async function (id) {
    if (id) return await apiProvider.getDoc(`${this.collection}/${id}`)
    return await apiProvider.getDoc(`${this.collection}/${auth.currentUser.uid}`)
  },
  set: async function (data, id) {
    if (id) return await apiProvider.setDoc(`${this.collection}/${id}`, data, true)
    return await apiProvider.setDoc(`${this.collection}/${auth.currentUser.uid}`, data, true)
  },
  updateProfile: async function (data, id) {
    const { name, lastName } = data
    const displayName = name + ' ' + lastName
    try {
      if (id) {
        await updateProfile(id, { displayName })
        await apiProvider.updateDoc(`${this.collection}/${id}`, { displayName, name, lastName })
        return
      }
      await updateProfile(auth.currentUser, { displayName })
      await apiProvider.updateDoc(`${this.collection}/${auth.currentUser.uid}`, { displayName, name, lastName })
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  updateEmail: async function (email, id) {
    try {
      if (id) {
        await updateEmail(id, email)
        await apiProvider.updateDoc(`${this.collection}/${id}`, { email })
        return
      }
      await updateEmail(auth.currentUser, email)
      await apiProvider.updateDoc(`${this.collection}/${auth.currentUser.uid}`, { email })
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  updatePassword: async function (password) {
    return await updatePassword(auth.currentUser, password)
  }
}

const apiCustomers = {
  collection: 'customers',
  get: async function (id) {
    if (id) return await apiProvider.getDoc(`${this.collection}/${id}`)
    return await apiProvider.getDocs(`${this.collection}`, (orderBy('createdAt', 'desc')))
  },
  add: async function (data) {
    return await apiProvider.addDoc(`${this.collection}`, { ...data, createdAt: serverTimestamp() })
  },
  update: async function (data, id) {
    return await apiProvider.updateDoc(`${this.collection}/${id}`, { ...data, lastEditAt: serverTimestamp(), lastEditBy: auth.currentUser.uid })
  },
  delete: async function (id) {
    return await apiProvider.deleteDoc(`${this.collection}/${id}`)
  },
  getServices: async function (id) {
    return await apiProvider.getDocs(`${this.collection}/${id}/services`, (orderBy('date', 'desc')))
  }
}

const apiServices = {
  collection: 'services',
  get: async function (id) {
    if (id) return await apiProvider.getDoc(`${this.collection}/${id}`)
    try {
      const data = await apiProvider.getDocs(`${this.collection}`, (orderBy('createdAt', 'desc')))
      const concatData = async () => {
        return await Promise.all(data.map(async (item) => {
          const customer = await apiCustomers.get(item.customer)
          return {
            ...item,
            ...customer
          }
        }))
      }
      return await concatData()
    } catch (err) {
      console.error(err)
      throw err
    }
  },
  add: async function (data) {
    return await apiProvider.addDoc(`${this.collection}`, { ...data, createdAt: serverTimestamp() })
  },
  update: async function (data, id) {
    const fieldsToDelete = {}
    if (data.future === 'yes') {
      fieldsToDelete.deceasedBirthPlace = deleteField()
      fieldsToDelete.deceasedBuryPlace = deleteField()
      fieldsToDelete.deceasedDate = deleteField()
      fieldsToDelete.deceasedDeathPlace = deleteField()
      fieldsToDelete.deceasedDrCertifier = deleteField()
      fieldsToDelete.deceasedName = deleteField()
      fieldsToDelete.deceasedLastName = deleteField()
      fieldsToDelete.deceasedSLastName = deleteField()
    }
    return await apiProvider.updateDoc(`${this.collection}/${id}`, { ...data, ...fieldsToDelete, lastEditAt: serverTimestamp(), lastEditBy: auth.currentUser.uid })
  },
  delete: async function (id) {
    return await apiProvider.deleteDoc(`${this.collection}/${id}`)
  }
}

export {
  apiAuth,
  apiUsers,
  apiCustomers,
  apiServices
}
