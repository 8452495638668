import Section from '../commons/Section'

import { Input, Col, Row } from 'antd'
import debounce from 'lodash/debounce'
import debuur from 'lodash/deburr'
import toLower from 'lodash/toLower'

import { SearchOutlined } from '@ant-design/icons'

const normalize = (value) => {
  return toLower(debuur(value))
}

const Search = ({ placeholder, onSearch, data = [] }) => {
  const handleSearch = (value) => {
    console.log(data)
    if (value.trim()) {
      const searchTerm = normalize(value)
      const results = data.filter((item) => normalize(item.name).includes(searchTerm) || normalize(item.tel).includes(searchTerm) || normalize(item.deceasedName).includes(searchTerm) || normalize(item.contractId).includes(searchTerm))
      return onSearch(results)
    }
    onSearch(null)
  }

  const handleChange = debounce((ev) => handleSearch(ev.target.value), 400)

  return (
    <Row>
      <Col
        xs={24}
        lg={{ span: 16, offset: 4 }}
      >
        <Section
          transparent
        >
          <Input.Search
            name='search-bar'
            placeholder={placeholder}
            allowClear
            enterButton='Buscar'
            onSearch={handleSearch}
            onChange={handleChange}
            addonBefore={<SearchOutlined />}
          />
        </Section>
      </Col>
    </Row>
  )
}

export default Search
