import { useCallback } from 'react'

import { apiAuth } from '../api'

const useSignOut = () => {
  const signOut = useCallback(() => {
    return new Promise((resolve, reject) => {
      apiAuth.signOut()
        .then(() => {
          resolve()
        })
        .catch((err) => {
          console.error(err)
          reject(err)
        })
    })
  }, [])

  return { signOut }
}

export default useSignOut
