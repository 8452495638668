import { useState, useCallback } from 'react'

import { apiCustomers } from '../api'

const useDeleteCustomer = () => {
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteCustomer = useCallback((id) => {
    return new Promise((resolve, reject) => {
      setIsDeleting(true)
      apiCustomers.delete(id)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsDeleting(false)
        })
    })
  }, [])

  return { deleteCustomer, isDeleting }
}

export default useDeleteCustomer
