import { Form, Input, Col, Row, DatePicker } from 'antd'

const DeceasedForm = () => (
  <Row gutter={24}>
    <Col
      xs={24}
      lg={8}
    >
      <Form.Item
        label='Nombre'
        name='deceasedName'
        rules={[
          {
            required: true,
            message: 'No olvides el nombre del finado'
          }
        ]}
        required
        hasFeedback
      >
        <Input
          placeholder='Nombre(s)'
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={8}
    >
      <Form.Item
        label='Apellido paterno'
        name='deceasedLastName'
        rules={[
          {
            required: true,
            message: 'No olvides el apellido del finado'
          }
        ]}
        required
        hasFeedback
      >
        <Input
          placeholder='Apellido'
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={8}
    >
      <Form.Item
        label='Apellido materno'
        name='deceasedSLastName'
        hasFeedback
      >
        <Input
          placeholder='Apellido'
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={12}
    >
      <Form.Item
        label='Lugar de nacimiento'
        name='deceasedBirthPlace'
        hasFeedback
      >
        <Input
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={12}
    >
      <Form.Item
        label='Lugar de defunción'
        name='deceasedDeathPlace'
        hasFeedback
      >
        <Input
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={12}
    >
      <Form.Item
        label='Lugar para sepultar'
        name='deceasedBuryPlace'
        hasFeedback
      >
        <Input
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={12}
    >
      <Form.Item
        label='Doctor certificante'
        name='deceasedDrCertifier'
        hasFeedback
      >
        <Input
          placeholder='Nombre del doctor'
          type='text'
        />
      </Form.Item>
    </Col>
    <Col
      xs={24}
      lg={8}
    >
      <Form.Item
        label='Fecha de uso'
        name='deceasedDate'
        rules={[
          {
            required: true,
            message: 'Necesitas seleccionar una fecha'
          }
        ]}
        required
        hasFeedback
      >
        <DatePicker
          placeholder='Selecciona una fecha'
          style={{ minWidth: '100%' }}
          format='DD/MM/YYYY'
        />
      </Form.Item>
    </Col>
  </Row>
)

export default DeceasedForm
