import style from './Settings.module.scss'

import { useState } from 'react'
import { Button, Form, Input, message } from 'antd'

import Section from '../../commons/Section'
import Reauthenticate from '../Reauthenticate'

import useUpdatePassword from '../../../hooks/useUpdatePassword'

const Password = () => {
  const [form] = Form.useForm()
  const { updatePassword, isLoading } = useUpdatePassword()
  const [messageApi, contextHolder] = message.useMessage()
  const [showReauthentication, setShowReauthentication] = useState(false)

  const handleFinish = ({ password }) => {
    updatePassword(password)
      .then((requiresRecentLogin) => {
        if (requiresRecentLogin) {
          setShowReauthentication(true)
          return
        }
        messageApi.open({
          type: 'success',
          content: 'Tu contraseña ha sido modificada correctamente.'
        })
        form.resetFields()
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  const handleReauth = () => {
    setShowReauthentication(false)
    const password = form.getFieldValue('password')
    updatePassword(password)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Tu contraseña ha sido modificada correctamente.'
        })
        form.resetFields()
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  return (
    <Section marginH>
      {contextHolder}
      <Form
        name='password'
        form={form}
        layout='vertical'
        onFinish={handleFinish}
        autoComplete='off'
        wrapperCol={{
          xs: { span: 24 },
          lg: { span: 12 }
        }}
      >
        <Form.Item
          label='Nueva contraseña'
          name='password'
          rules={[
            {
              required: true,
              message: 'Introduce tu nueva contraseña'
            },
            {
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              message: 'Tu contraseña deberá contener 8 caracteres mínimo, al menos una mayúscula y un número'
            }
          ]}
          required={false}
          hasFeedback
          tooltip='Tu contraseña deberá contener 8 caracteres mínimo, al menos una mayúscula y un número'
        >
          <Input.Password
            placeholder='Password'
          />
        </Form.Item>
        <Form.Item
          label='Confirmar contraseña'
          name='confirmPassword'
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Confirma tu nueva contraseña'
            },
            ({ getFieldValue }) => ({
              validator (_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(new Error('¡Tus contraseñas no coinciden!'))
              }
            })
          ]}
          required={false}
          hasFeedback
          tooltip='Vuelve a introducir la nueva contraseña'
        >
          <Input.Password
            placeholder='Password'
          />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            className={style.submit}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Guardar contraseña
          </Button>
        </Form.Item>
      </Form>
      <Reauthenticate
        show={showReauthentication}
        close={() => setShowReauthentication(false)}
        onReauth={handleReauth}
      />
    </Section>
  )
}

export default Password
