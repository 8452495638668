import { useState, useCallback } from 'react'

import { apiCustomers } from '../api'

const useAddCustomer = () => {
  const [isSaving, setIsSaving] = useState(false)

  const addCustomer = useCallback((data) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true)
      Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])
      apiCustomers.add(data)
        .then((docRef) => {
          const docId = docRef.id
          resolve(docId)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsSaving(false)
        })
    })
  }, [])

  return { addCustomer, isSaving }
}

export default useAddCustomer
