import { useState, useCallback } from 'react'

import { apiAuth } from '../api'

const useSignIn = () => {
  const [isSigning, setIsSigning] = useState(false)

  const signIn = useCallback(({ email, password, remember }) => {
    return new Promise((resolve, reject) => {
      setIsSigning(true)
      apiAuth.signIn(email, password, remember)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.field = 'email'
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-disabled':
              error.field = 'email'
              error.message = 'Esta cuenta se encuentra inactiva.'
              break
            case 'auth/user-not-found':
              error.field = 'email'
              error.message = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              error.field = 'password'
              error.message = 'Tu contraseña es incorrecta.'
              break
            case 'auth/too-many-requests':
              error.code = err.code
              error.message = 'El acceso a esta cuenta ha sido bloqueado temporalmente debido a varios intentos fallidos .'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setIsSigning(false)
        })
    })
  }, [])

  return { signIn, isSigning }
}

export default useSignIn
