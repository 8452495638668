import style from './Settings.module.scss'

import { useEffect } from 'react'
import { Button, Form, Input, message } from 'antd'

import Section from '../../commons/Section'

import useUser from '../../../hooks/useUser'
import useUpdateName from '../../../hooks/useUpdateName'

const Profile = () => {
  const [form] = Form.useForm()
  const [user] = useUser()
  const { updateName, isLoading } = useUpdateName()
  const [messageApi, contextHolder] = message.useMessage()

  useEffect(() => {
    const { name, lastName } = user
    form.setFieldsValue({ name, lastName })
  }, [form, user])

  const onFinish = (values) => {
    updateName(values)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Tu perfil ha sido actualizado correctamente.'
        })
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  return (
    <Section marginH>
      {contextHolder}
      <Form
        name='profile'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        autoComplete='off'
        wrapperCol={{
          xs: { span: 24 },
          lg: { span: 12 }
        }}
      >
        <Form.Item
          label='Nombre'
          name='name'
          rules={[
            {
              required: true,
              message: 'No olvides escribir tu nombre'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input
            placeholder='Nombre(s)'
            type='text'
          />
        </Form.Item>
        <Form.Item
          label='Apellido'
          name='lastName'
          rules={[
            {
              required: true,
              message: 'No olvides escribir un apellido'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input
            placeholder='Apellido(s)'
            type='text'
          />
        </Form.Item>
        <Form.Item noStyle>
          <Button
            className={style.submit}
            type='primary'
            htmlType='submit'
            loading={isLoading}
          >
            Guardar perfil
          </Button>
        </Form.Item>
      </Form>
    </Section>
  )
}

export default Profile
