import { createContext, useEffect, useReducer, useRef } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { serverTimestamp } from 'firebase/firestore'

import { auth } from '../lib/firebase'
import { apiUsers } from '../api'

const reducer = (state, action) => {
  switch (action.type) {
    case 'sign_in':
      return action.value
    case 'sign_out':
      return null
    case 'change_name': {
      const { name, lastName } = action.value
      return {
        ...state,
        name,
        lastName,
        displayName: name + ' ' + lastName
      }
    }
    case 'change_email':
      return {
        ...state,
        email: action.value
      }
    default:
      break
  }
  throw Error('Acción desconocida: ' + action.type)
}

const UserContext = createContext()
const { Provider } = UserContext

const UserProvider = ({ children }) => {
  const localUser = useRef(window.localStorage.getItem('authUser'))
  const initialState = useRef(localUser.current ? JSON.parse(localUser.current) : null)
  const [user, dispatch] = useReducer(reducer, initialState.current)

  useEffect(() => {
    const authListener = onAuthStateChanged(auth, async (userCredential) => {
      try {
        if (userCredential) {
          const userObject = {
            uid: userCredential.uid,
            email: userCredential.email,
            displayName: userCredential.displayName
          }
          window.localStorage.setItem('authUser', JSON.stringify(userObject))
          await apiUsers.set({ lastLogin: serverTimestamp(), ...userObject })
          const userData = await apiUsers.get()
          dispatch({
            type: 'sign_in',
            value: {
              ...userObject,
              name: userData?.name,
              lastName: userData?.lastName,
              role: userData?.role,
              isReady: true
            }
          })
        } else {
          window.localStorage.clear()
          dispatch({
            type: 'sign_out'
          })
        }
      } catch (err) {
        console.error(err)
        dispatch({
          type: 'sign_out'
        })
      }
    })

    return () => {
      authListener()
    }
  }, [])

  const value = [user, dispatch]

  return <Provider value={value}>{children}</Provider>
}

export { UserContext, UserProvider }
