import { useEffect } from 'react'

import Profile from '../modules/Settings/Profile'

import useNavigation from '../../hooks/useNavigation'

const EditProfile = () => {
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Editar perfil',
      description: 'Edita la información que se mostrará como tu perfil en la aplicación.',
      items: [
        { title: 'Configuración' },
        { title: 'Editar perfil' }
      ],
      isContinual: true
    })
  }, [setNavigation])

  return (
    <>
      <Profile />
    </>
  )
}

export default EditProfile
