import style from './Section.module.scss'

const Section = ({ children, transparent, marginH, marginV, className }) => {
  let classes = style.container
  if (transparent) classes = `${classes} ${style.transparent}`
  if (marginH) classes = `${classes} ${style.marginH}`
  if (marginV) classes = `${classes} ${style.marginV}`
  if (className) classes = `${classes} ${className}`

  return (
    <div className={classes}>
      <div className={style.content}>
        {children}
      </div>
    </div>
  )
}

export default Section
