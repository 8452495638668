import style from './Dashboard.module.scss'

import { useState, useEffect } from 'react'
import { Layout, Avatar, Space, Typography, Menu, Dropdown } from 'antd'
import { UserOutlined, ContactsOutlined, ContactsFilled, SnippetsOutlined, SnippetsFilled, SettingOutlined, MailOutlined, LockOutlined } from '@ant-design/icons'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import Navbar from './Navbar'
import Header from './Header'
import Brand from './Brand'

import useUser from '../../hooks/useUser'
import useSignOut from '../../hooks/useSignOut'
import useNavigation from '../../hooks/useNavigation'

const { Sider, Content } = Layout
const { Text } = Typography

const ITEMS = [
  {
    label: 'Clientes',
    key: 'customers',
    icon: <ContactsOutlined />,
    icon_active: <ContactsFilled />
  },
  {
    label: 'Servicios',
    key: 'services',
    icon: <SnippetsOutlined />,
    icon_active: <SnippetsFilled />
  },
  {
    label: 'Configuración',
    key: 'settings',
    icon: <SettingOutlined />,
    children: [
      {
        label: 'Editar perfil',
        key: 'edit-profile',
        icon: <UserOutlined />
      },
      {
        label: 'Cambiar email',
        key: 'change-email',
        icon: <MailOutlined />
      },
      {
        label: 'Cambiar contraseña',
        key: 'change-password',
        icon: <LockOutlined />
      },
      {
        label: 'Cerrar sesión',
        key: 'logout',
        danger: true
      }
    ]
  }
]

const itemsDropdown = [
  {
    label: 'Editar perfil',
    key: 'settings/edit-profile',
    icon: <UserOutlined />
  },
  {
    label: 'Cambiar email',
    key: 'settings/change-email',
    icon: <MailOutlined />
  },
  {
    label: 'Cambiar contraseña',
    key: 'settings/change-password',
    icon: <LockOutlined />
  },
  { type: 'divider' },
  {
    label: 'Cerrar sesión',
    key: 'logout',
    danger: true
  }
]

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false)
  const [user] = useUser()
  const { signOut } = useSignOut()
  const navigate = useNavigate()
  const location = useLocation()
  const [activeKeys, setActiveKeys] = useState([])
  const { navigation } = useNavigation()

  useEffect(() => {
    const keys = location.pathname.split('/')
    keys.splice(0, 2)
    setActiveKeys(keys)
  }, [location])

  const handleClick = (ev) => {
    const { key, keyPath } = ev
    if (key === 'logout') {
      signOut()
    } else {
      const path = keyPath.reverse().join('/')
      navigate('/app/' + path)
    }
  }

  return (
    <Layout className={style.container}>
      <Sider
        className={style.sider}
        width={264}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        breakpoint='lg'
      >
        <Brand collapsed={collapsed} />
        <Menu
          mode='inline'
          theme='dark'
          items={ITEMS}
          className={style.menu}
          onClick={handleClick}
          selectedKeys={activeKeys}
        />
      </Sider>
      <Layout className={`${style.content} ${collapsed ? style.collapsed : ''}`}>
        <header className={`${style.header} ${navigation.hasBack ? style.interior : ''}`}>
          <Dropdown
            menu={{
              items: itemsDropdown,
              onClick: handleClick
            }}
            trigger={['click']}
          >
            <Space>
              <Avatar
                className={style.avatar}
                icon={<UserOutlined />}
              />
              <Text className={style.displayName}>{user.displayName || user.email}</Text>
            </Space>
          </Dropdown>
        </header>
        <Content className={style.main}>
          <Header {...navigation} />
          <Outlet />
        </Content>
        <Navbar
          items={ITEMS}
          activeKeys={activeKeys}
        />
      </Layout>
    </Layout>
  )
}

export default Dashboard
