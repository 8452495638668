import useSWR from 'swr'
import { useMemo } from 'react'

import { apiServices } from '../api'

const useServices = () => {
  const { data, error, isLoading } = useSWR('getServices', () => apiServices.get())

  const services = useMemo(() => {
    if (!isLoading && !error) {
      if (Array.isArray(data)) {
        return data.map((doc) => {
          const name = `${doc.name} ${doc.lastName}${doc.sLastName ? ` ${doc.sLastName}` : ''}`
          const deceasedName = doc.deceasedName ? (`${doc.deceasedName} ${doc.deceasedLastName}${doc.deceasedSLastName ? ` ${doc.deceasedSLastName}` : ''}`) : ''
          return ({
            id: doc.id,
            key: doc.id,
            contractId: doc.contractId,
            type: doc.type,
            name,
            deceasedName,
            future: doc.future
          })
        })
      }
      return null
    }
  }, [error, isLoading, data])

  return { data: services, error, isLoading }
}

export default useServices
