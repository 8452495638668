import { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import Add from '../modules/Customer/Add'
import Result from '../modules/Result'

import useNavigation from '../../hooks/useNavigation'

const AddCustomer = () => {
  const navigate = useNavigate()
  const { setNavigation } = useNavigation()
  const [showResult, setShowResult] = useState(false)
  const customerId = useRef(null)

  useEffect(() => {
    setNavigation({
      title: 'Nuevo cliente',
      items: [
        { title: <Link to='/app/customers'>Clientes</Link> },
        { title: 'Agregar cliente' }
      ],
      hasBack: true
    })
  }, [setNavigation])

  return (
    <>
      {showResult
        ? <Result
            status='success'
            title='El cliente ha sido registrado'
            subTitle='Ahora podrás gestionar los servicios de este cliente. Puedes continuar agregando un servicio o regresando a la lista de clientes.'
            extra={[
              <Button key='add' icon={<PlusCircleOutlined />} onClick={() => navigate(`/app/services/add?customerId=${customerId.current}`)}>Añadir un servicio</Button>, /* TODO: Agregar ruta de cliente */
              <Button type='primary' key='back' onClick={() => navigate('/app/customers')}>Regresar a clientes</Button>
            ]}
          />
        : <Add
            onSuccess={(id) => { customerId.current = id; setShowResult(true) }}
          />}
    </>
  )
}

export default AddCustomer
