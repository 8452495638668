import { useState, useCallback } from 'react'

import { apiServices } from '../api'

const useUpdateService = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  const updateService = useCallback((data, id) => {
    return new Promise((resolve, reject) => {
      console.log(data)
      setIsUpdating(true)
      Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])
      apiServices.update(data, id)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsUpdating(false)
        })
    })
  }, [])

  return { updateService, isUpdating }
}

export default useUpdateService
