import style from './Header.module.scss'

import { Typography, Breadcrumb, Button } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { Title, Text } = Typography

const Header = ({ title, description, items, action, hasBack, isContinual }) => {
  const navigate = useNavigate()

  return (
    <div className={`${style.container} ${hasBack ? style.interior : ''} ${isContinual ? style.continual : ''}`}>
      <div className={style.content}>
        {items && <Breadcrumb items={items} className={style.breadcrumb} />}
        <div className={style.heading}>
          {hasBack &&
            <Button
              type='text'
              shape='circle'
              icon={<LeftOutlined />}
              onClick={() => navigate(-1)}
              className={style.back}
            />}
          <Title
            level={2}
            style={{ marginBottom: 0 }}
            className={style.title}
          >
            {title}
          </Title>
          <div className={style.action}>
            {action}
          </div>
        </div>
        {description && (
          <div className={style.description}>
            <Text type='secondary'>{description}</Text>
          </div>
        )}
      </div>
    </div>
  )
}

export default Header
