import style from './Auth.module.scss'
import imgSrc from '../../assets/images/imagotipo.png'

import { Outlet } from 'react-router-dom'

const Auth = () => {
  return (
    <div className={style.container}>
      <div className={style.header}>
        <h1><img src={imgSrc} alt='Casanova Servicios Funerarios' /></h1>
      </div>
      <div className={style.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Auth
