import { generate } from '@pdfme/generator'
import { useState } from 'react'
import capitalize from 'lodash/capitalize'

import defaultTemplate from '../components/modules/Service/template'
import quoteTemplate from '../components/modules/Service/template_quote'
import { SERVICES_SPECS } from '../components/modules/Service/services'
import currencyToWords from '../utils/currencyToWords'

import useUser from './useUser'

const useGeneratePDF = () => {
  const [user] = useUser()
  const [isCreating, setIsCreating] = useState(false)

  const generatePDF = async (service, documentType) => {
    try {
      setIsCreating(true)
      const template = documentType === 'quote' ? quoteTemplate : defaultTemplate

      const inputs = [{
        id: service.contractId || '',
        date: new Date().toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' }),
        dateContract: new Date(service.date).toLocaleDateString('es-MX', { year: 'numeric', month: 'numeric', day: 'numeric' }),
        name: service.customerName || '',
        tel: service.customerTel || '',
        deceasedName: service.deceasedName ? `${service.deceasedName} ${service.deceasedLastName}${service.deceasedSLastName ? ` ${service.deceasedSLastName}` : ''}` : 'N/A - Servicio disponible',
        address: 'Calle, número y colonia.',
        city: 'Población o municipio.',
        state: 'Estado',
        type: service.type || '',
        totalCost: new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(service.totalCost),
        totalInWords: capitalize(currencyToWords(service.totalCost)) + ' M.N.',
        userName: user.displayName || 'Casanova Servicios Funerarios'
      }]

      const specs = [...service.specs]

      specs.sort((a, b) => a.localeCompare(b))
        .forEach((spec, index) => {
          inputs[0][`service${index || ''}_name`] = SERVICES_SPECS.find((type) => spec === type.value).label
          inputs[0][`service${index || ''}_details`] = service[`specs_${spec}_details`] || ''
          inputs[0][`service${index || ''}_cost`] = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(service[`specs_${spec}_cost`])
        })

      const pdf = await generate({ template, inputs })
      const blob = new window.Blob([pdf.buffer], { type: 'application/pdf' })
      window.open(URL.createObjectURL(blob))
      return pdf
    } catch (error) {
      console.error(error)
    } finally {
      setIsCreating(false)
    }
  }

  return { generatePDF, isCreating }
}

export default useGeneratePDF
