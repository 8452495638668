import { useState, useCallback } from 'react'

import { apiAuth } from '../api'

const useReauthenticate = () => {
  const [isReauthenticating, setIsReauthenticating] = useState(false)

  const reauthenticate = useCallback((password) => {
    return new Promise((resolve, reject) => {
      setIsReauthenticating(true)
      apiAuth.reauthenticate(password)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          const error = {
            field: null,
            message: '',
            code: ''
          }
          switch (err.code) {
            case 'auth/invalid-email':
              error.message = 'La cuenta no es un email válido.'
              break
            case 'auth/user-not-found':
              error.message = 'Esta cuenta no pertenece a ningún usuario de la plataforma.'
              break
            case 'auth/wrong-password':
              error.field = 'password'
              error.message = 'Tu contraseña es incorrecta.'
              break
            case 'auth/too-many-requests':
              error.field = 'password'
              error.message = 'Cuenta bloqueada temporalmente.'
              break
            default:
              error.code = err.code
              error.message = err.message
              break
          }
          console.error(err)
          reject(error)
        })
        .finally(() => {
          setIsReauthenticating(false)
        })
    })
  }, [])

  return { reauthenticate, isReauthenticating }
}

export default useReauthenticate
