import { useState, createContext, useCallback } from 'react'

const NavigationContext = createContext()
const { Provider } = NavigationContext

const initialState = {
  title: null,
  description: null,
  items: null,
  action: null,
  hasBack: false,
  isContinual: false
}

const NavigationProvider = ({ children }) => {
  const [navigation, setNavigation] = useState(initialState)

  const set = useCallback((newState) => {
    setNavigation({
      ...initialState,
      ...newState
    })
  }, [])

  const value = [navigation, set]

  return <Provider value={value}>{children}</Provider>
}

export { NavigationContext, NavigationProvider }
