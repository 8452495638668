import style from './Customer.module.scss'
import ESTADOS_MEXICO from './estados-mx'

import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Button, Form, Input, Select, Typography, Col, Row, message, Popconfirm, Table } from 'antd'
import { PlusCircleOutlined } from '@ant-design/icons'

import Section from '../../commons/Section'

import useUpdateCustomer from '../../../hooks/useUpdateCustomer'
import useDeleteCustomer from '../../../hooks/useDeleteCustomer'

const { Title, Text } = Typography

const columns = [
  {
    title: 'Tipo de servicio',
    dataIndex: 'type',
    key: 'type',
    render: (value, record) => <Link to={`/app/services/edit/${record.id}`}>{value}</Link>,
    sorter: (a, b) => a.type?.localeCompare(b.type)
  },
  {
    title: 'Fecha de contratación',
    dataIndex: 'date',
    key: 'date',
    render: (value) => value ? new Date(value).toLocaleDateString() : '',
    sorter: (a, b) => a.date?.localeCompare(b.date)
  }
]

const Edit = ({ customer, onSuccess, disabled }) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { updateCustomer, isUpdating } = useUpdateCustomer()
  const { deleteCustomer, isDeleting } = useDeleteCustomer()
  const [messageApi, contextHolder] = message.useMessage()

  const handleFinish = (values) => {
    updateCustomer(values, customer.id)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Los cambios han sido guardados correctamente.'
        })
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  const handleConfirm = () => {
    deleteCustomer(customer.id)
      .then(() => {
        onSuccess()
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: err.message
        })
      })
  }

  useEffect(() => {
    if (customer) {
      const fieldsValue = {
        ...customer,
        state: ESTADOS_MEXICO.find((state) => state.value === customer.state)?.value
      }
      form.setFieldsValue(fieldsValue)
    }
  }, [customer, form])

  return (
    <Form
      name='customer'
      form={form}
      layout='vertical'
      onFinish={handleFinish}
      autoComplete='off'
      className={style.container}
      disabled={disabled || isDeleting}
      requiredMark={!disabled}
      scrollToFirstError
    >
      {contextHolder}
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Contratante</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Nombre'
              name='name'
              rules={[
                {
                  required: true,
                  message: 'No olvides el nombre'
                }
              ]}
              required
            >
              <Input
                placeholder='Nombre(s)'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido paterno'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el apellido'
                }
              ]}
              required
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido materno'
              name='sLastName'
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 16, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Domicilio'
              name='address'
            >
              <Input
                placeholder='Calle, número, colonia'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            {disabled
              ? (
                <Form.Item
                  label='Estado'
                  name='state'
                >
                  <Input
                    type='text'
                    value={ESTADOS_MEXICO.find((state) => state.value === customer.state)?.value}
                  />
                </Form.Item>
                )
              : (
                <Form.Item
                  label='Estado'
                  name='state'
                  rules={[
                    {
                      required: true,
                      message: 'Necesitas seleccionar un estado'
                    }
                  ]}
                  initialValue='Sinaloa'
                  required
                >
                  <Select
                    placeholder='Selecciona un estado'
                    options={ESTADOS_MEXICO}
                    className={style.select}
                  />
                </Form.Item>
                )}
          </Col>
          <Col
            xs={24}
            lg={{ span: 8, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Ciudad'
              name='city'
              rules={[
                {
                  required: true,
                  message: 'No olvides la ciudad'
                }
              ]}
              required
            >
              <Input
                placeholder='Ciudad'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Teléfono'
              name='tel'
              rules={[
                {
                  required: true,
                  message: 'No olvides el teléfono'
                }
              ]}
              required
            >
              <Input
                placeholder='999 999 9999'
                type='tel'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  type: 'email',
                  message: 'Introduce una cuenta de correo válida'
                }
              ]}
            >
              <Input
                placeholder='cuenta@correo.com'
                type='email'
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Section marginV>
        <Form.Item>
          <Title level={3} className={style.title}>Referente asociado</Title>
        </Form.Item>
        <Row gutter={24}>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Nombre'
              name='secName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el nombre'
                }
              ]}
              required
            >
              <Input
                placeholder='Nombre(s)'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido paterno'
              name='secLastName'
              rules={[
                {
                  required: true,
                  message: 'No olvides el apellido'
                }
              ]}
              required
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Apellido materno'
              name='secSLastName'
            >
              <Input
                placeholder='Apellido'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={{ span: 16, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Domicilio'
              name='secAddress'
            >
              <Input
                placeholder='Calle, número, colonia'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            {disabled
              ? (
                <Form.Item
                  label='Estado'
                  name='secState'
                >
                  <Input
                    type='text'
                    value={ESTADOS_MEXICO.find((state) => state.value === customer.state)?.value}
                  />
                </Form.Item>
                )
              : (
                <Form.Item
                  label='Estado'
                  name='secState'
                  rules={[
                    {
                      required: true,
                      message: 'Necesitas seleccionar un estado'
                    }
                  ]}
                  initialValue='Sinaloa'
                  required
                >
                  <Select
                    placeholder='Selecciona un estado'
                    options={ESTADOS_MEXICO}
                    className={style.select}
                  />
                </Form.Item>
                )}
          </Col>
          <Col
            xs={24}
            lg={{ span: 8, offset: 8, pull: 8 }}
          >
            <Form.Item
              label='Ciudad'
              name='secCity'
              rules={[
                {
                  required: true,
                  message: 'No olvides la ciudad'
                }
              ]}
              required
            >
              <Input
                placeholder='Ciudad'
                type='text'
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={8}
          >
            <Form.Item
              label='Teléfono'
              name='secTel'
              rules={[
                {
                  required: true,
                  message: 'No olvides el teléfono'
                }
              ]}
              required
            >
              <Input
                placeholder='999 999 9999'
                type='tel'
              />
            </Form.Item>
          </Col>
        </Row>
      </Section>
      <Row gutter={{ xs: 12, md: 24 }}>
        <Col xs={24} lg={16}>
          <Section marginV>
            <Form.Item>
              <Title level={3} className={style.title}>Servicios adquiridos</Title>
            </Form.Item>
            {customer.services?.length > 0
              ? (
                <Table
                  dataSource={customer.services}
                  columns={columns}
                  size='small'
                />
                )
              : (
                <div className={style.empty}>
                  <Text type='secondary'>Este cliente aún no cuenta servicios.</Text>
                  <Button
                    disabled={false}
                    icon={<PlusCircleOutlined />}
                    onClick={() => navigate(`/app/services/add?customerId=${customer.id}`)}
                  >
                    Añadir un servicio
                  </Button>
                </div>)}
          </Section>
        </Col>
        {!disabled && (
          <Col xs={24} lg={8}>
            <Section
              className={style.actions}
            >
              <Form.Item>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isUpdating}
                >
                  Guardar cambios
                </Button>
              </Form.Item>
              {customer.services?.length > 0
                ? (
                  <Text type='secondary'>El cliente cuenta con servicios activos por lo que no puede ser dado de baja del sistema.</Text>
                  )
                : (
                  <Form.Item noStyle>
                    <Popconfirm
                      title='¿Estás seguro de querer eliminar este cliente?'
                      onConfirm={handleConfirm}
                      okText='Si, eliminar'
                      cancelText='No, cancelar'
                    >
                      <Button
                        type='link'
                        danger
                        loading={isDeleting}
                      >
                        Eliminar cliente
                      </Button>
                    </Popconfirm>
                  </Form.Item>)}
            </Section>
          </Col>
        )}
      </Row>
    </Form>
  )
}

export default Edit
