const ESTADOS_MEXICO = [
  { value: 'Aguascalientes' },
  { value: 'Baja California' },
  { value: 'Baja California Sur' },
  { value: 'Campeche' },
  { value: 'Chiapas' },
  { value: 'Chihuahua' },
  { value: 'Ciudad de México' },
  { value: 'Coahuila' },
  { value: 'Colima' },
  { value: 'Durango' },
  { value: 'Estado de México' },
  { value: 'Guanajuato' },
  { value: 'Guerrero' },
  { value: 'Hidalgo' },
  { value: 'Jalisco' },
  { value: 'Michoacán' },
  { value: 'Morelos' },
  { value: 'Nayarit' },
  { value: 'Nuevo León' },
  { value: 'Oaxaca' },
  { value: 'Puebla' },
  { value: 'Querétaro' },
  { value: 'Quintana Roo' },
  { value: 'San Luis Potosí' },
  { value: 'Sinaloa' },
  { value: 'Sonora' },
  { value: 'Tabasco' },
  { value: 'Tamaulipas' },
  { value: 'Tlaxcala' },
  { value: 'Veracruz' },
  { value: 'Yucatán' },
  { value: 'Zacatecas' }
]

export default ESTADOS_MEXICO
