import { useState, useCallback } from 'react'

import { apiServices } from '../api'

const useDeleteService = () => {
  const [isDeleting, setIsDeleting] = useState(false)

  const deleteService = useCallback((id) => {
    return new Promise((resolve, reject) => {
      setIsDeleting(true)
      apiServices.delete(id)
        .then(() => {
          resolve()
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsDeleting(false)
        })
    })
  }, [])

  return { deleteService, isDeleting }
}

export default useDeleteService
