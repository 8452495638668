import style from './Brand.module.scss'
import imagotipoSrc from '../../assets/images/imagotipo.png'

const Brand = ({ collapsed }) => {
  return (
    <div className={`${style.container} ${collapsed ? style.collapsed : style.uncollapsed}`}>
      <div className={style.content}>
        <img src={imagotipoSrc} className={style.imagotipo} alt='Casanova Servicios Funerarios' />
      </div>
    </div>
  )
}

export default Brand
