import style from './Loader.module.scss'

import { Spin } from 'antd'

const Loader = () => {
  return (
    <div className={style.container}><Spin size='large' /></div>
  )
}

export default Loader
