import style from './Auth.module.scss'

import { useState } from 'react'
import { Button, Form, Input, Typography, message } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link, useNavigate } from 'react-router-dom'

import useResetPassword from '../../../hooks/useResetPassword'

const { Text, Title, Paragraph } = Typography

const Login = () => {
  const [form] = Form.useForm()
  const { resetPassword, isLoading } = useResetPassword()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [success, setSuccess] = useState(false)

  const onFinish = ({ email }) => {
    resetPassword(email)
      .then(() => {
        setSuccess(true)
      })
      .catch((err) => {
        if (err.field) {
          form.setFields([
            { name: err.field, errors: [err.message] }
          ])
        } else {
          messageApi.open({
            type: 'error',
            content: err.message
          })
        }
      })
  }

  if (success) {
    return (
      <>
        <div className={style.header}>
          <Title level={4}>Revisa tu bandeja de correo</Title>
          <Paragraph>Un correo de recuperación ha sido enviado a tu cuenta. Sigue los pasos que se te indican para restablecer tu contraseña.</Paragraph>
          <Paragraph>Si no ves el correo en tu bandeja de entrada, revisa en correo no deseado (spam).</Paragraph>
        </div>
        <Button
          type='primary'
          block
          onClick={() => navigate('/auth/login')}
        >
          Regresar a iniciar sesión
        </Button>
      </>
    )
  }
  return (
    <>
      {contextHolder}
      <Form
        name='reset-password'
        form={form}
        layout='vertical'
        onFinish={onFinish}
        autoComplete='off'
      >
        <div className={style.header}>
          <Title level={4}>Recuperar contraseña</Title>
          <Text>Introduce el correo electrónico asociado a tu cuenta para restablecer la contraseña.</Text>
        </div>
        <Form.Item
          label='Correo electrónico'
          name='email'
          rules={[
            {
              required: true,
              message: 'Ingresa tu cuenta de correo'
            },
            {
              type: 'email',
              message: 'Introduce una cuenta de correo válida'
            }
          ]}
          required={false}
          hasFeedback
        >
          <Input
            placeholder='correo@electronico'
            type='email'
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            htmlType='submit'
            block
            loading={isLoading}
          >
            Recuperar contraseña
          </Button>
        </Form.Item>
        <Form.Item noStyle>
          <Link
            to='/auth/login'
          >
            Regresar a iniciar sesión
          </Link>
        </Form.Item>
      </Form>
    </>
  )
}

export default Login
