import { useState, useCallback } from 'react'

import { apiServices } from '../api'

const useAddService = () => {
  const [isSaving, setIsSaving] = useState(false)

  const addService = useCallback((data) => {
    return new Promise((resolve, reject) => {
      setIsSaving(true)
      Object.keys(data).forEach((key) => data[key] === undefined && delete data[key])
      apiServices.add(data)
        .then((docRef) => {
          const docId = docRef.id
          resolve(docId)
        })
        .catch((err) => {
          reject(err)
        })
        .finally(() => {
          setIsSaving(false)
        })
    })
  }, [])

  return { addService, isSaving }
}

export default useAddService
