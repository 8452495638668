import { useEffect } from 'react'

import Email from '../modules/Settings/Email'

import useNavigation from '../../hooks/useNavigation'

const ChangeEmail = () => {
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Cambiar email',
      description: 'Puedes cambiar la cuenta de correo asociada a tu perfil. Recuerda que es tu usuario para iniciar sesión en la aplicación.',
      items: [
        { title: 'Configuración' },
        { title: 'Cambiar email' }
      ],
      isContinual: true
    })
  }, [setNavigation])

  return (
    <>
      <Email />
    </>
  )
}

export default ChangeEmail
