import { useEffect } from 'react'

import Password from '../modules/Settings/Password'

import useNavigation from '../../hooks/useNavigation'

const ChangePassword = () => {
  const { setNavigation } = useNavigation()

  useEffect(() => {
    setNavigation({
      title: 'Cambiar contraseña',
      description: 'Escribe tu nueva contraseña y confírmala en el otro campo. Es probable que se te pida la contraseña actual para realizar el cambio.',
      items: [
        { title: 'Configuración' },
        { title: 'Cambiar contraseña' }
      ],
      isContinual: true
    })
  }, [setNavigation])

  return (
    <>
      <Password />
    </>
  )
}

export default ChangePassword
